module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#0A9E50","theme_color":"#0A9E50","display":"minimal-ui","icon":"src/assets/logo-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ea8f5d75af0b83f2cb3e68674e6ac515"},
    },{
      plugin: require('../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"uri":"https://finteq-web.dev.smartfaktor.pl/api/graphql/"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["pl","en"],"defaultLanguage":"pl","siteUrl":"https://finteq-web.dev.smartfaktor.pl","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":"."},"pages":[{"matchPath":"/cennik-i-plany","languages":["pl"]},{"matchPath":"/koszyk/wybor-opcji-produktu","languages":["pl"]},{"matchPath":"/koszyk/podsumowanie","languages":["pl"]}],"redirect":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KRGRRXT","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
